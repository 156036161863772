/**
 * Module depedencies.
 */

var $ = window.$ = window.jQuery = require('jquery')

require('slick-carousel')
require('data-share')

/**
 * Validation.
 */

require('./modules/validation')

/**
 * Menu.
 */

require('./modules/menu')

/**
 * Popup.
 */

require('./modules/popup')

/**
 * Home News Slider
 */

require('./modules/home-news-slider')

/**
 * Go Back Button.
 */

$('.js-go-back').on('click', function () {
  window.history.go(-1)
  return false
})
