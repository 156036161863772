/**
 * Popup
 */

$('[data-popup]').on('click', function (e) {
  e.preventDefault()
  var $this = $(this)
  var $popup = $($this.data('popup'))
  $popup.add($this).toggleClass('is-active').off('clickout')
  var date = Date.now()


  if ($popup.hasClass('is-active')) {
    $popup.add($this).on('clickout', function (e) {
      this.removeClass('is-active')
    })
  }

  /*
  $(document).on('click.popup-' + date, function (e) {
    var $target = $(e.target)
    var condition = $target.closest($popup).length ||
                    $target.closest($this).length

    if (!condition) {
      $popup.add($this).removeClass('is-active')
      $(document).off('click.popup-' + date)
    }
  })
  */
})
