/**
 * Menu
 */

require('jquery-clickout')

var $menu = $('.js-menu')
var $button = $('.js-menu-button')
var $label = $('.js-menu-label')
var currentPage = $('body').data('page')

$('.js-page-' + currentPage).addClass('is-active')

$button.on('click', function () {
  $menu.add($button).add($label).toggleClass('is-active')
})

$button.add($menu).on('clickout', function (e) {
  this.removeClass('is-active')
})

/*
$(document).on('click.outside-menu', function (e) {
  var $target = $(e.target)
  var condition = $target.closest($menu).length ||
                  $target.closest($button).length

  if (!condition) {
    $menu.add($button).add($label).removeClass('is-active')
  }
})
*/
