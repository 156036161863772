/**
 * Dependencies.
 */

var swal = require('sweetalert')
require('jquery-validation')

/**
 * Feedback Handler.
 */

function feedbackHandler (feedback) {
  if (!feedback) {
    return feedbackHandler({
      message: 'Erro ao enviar dados'
    })
  }

  swal({
    text: feedback.message,
    title: feedback.title || '',
    type: feedback.success ? 'success' : 'error'
  })
}

/**
* Submit handler.
*/

var isPending = false

function submitHandler (form) {
  if (isPending) return
  isPending = true

  var $form = $(form)
  var $button = $form.find('[type="submit"]')
  var method = $form.attr('method') || 'GET'
  var url = $form.attr('action') || window.ajaxUrl
  var data = $form.serializeArray()

  $button.attr('disabled', true)
  $form.add($button).addClass('is-loading')

  $[method.toLowerCase()](url, data)
    .done(function (response) {
      try {
        response = JSON.parse(response)
      } catch (e) {
        feedbackHandler()
      }
      feedbackHandler(response)
    })
    .fail(function (err) {
      feedbackHandler()
      console.log(err)
    })
    .always(function () {
      $button.removeAttr('disabled')
      $form.add($button).removeClass('is-loading')
      isPending = false
    })
}

/**
 * Default messages.
 */

$.extend($.validator.messages, {
  required: 'Campo obrigatório',
  email: 'E-mail inválido'
})

/**
 * Call plugin.
 */

$('.js-validate').each(function () {
  $(this).validate({
    errorPlacement: function (err, input) {
      err.insertAfter(input.parent())
    },
    submitHandler: submitHandler
  })
})
